@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
.e-documenteditor .e-close::before {
  content: '\e825';
  font-family: 'e-icons';
  font-size: 14px;
}

.e-documenteditor .e-de-op-search-icon::before {
  content: '\e275';
  font-family: 'e-icons';
  font-size: 10px;
}

.e-documenteditor .e-arrow-up::before {
  content: '\e834';
  font-family: 'e-icons';
  font-size: 10px;
}

.e-documenteditor .e-arrow-down::before {
  content: '\e83d';
  font-family: 'e-icons';
  font-size: 10px;
}

.e-documenteditor .e-de-op .e-de-op-close-icon {
  font-size: 12px;
  height: 20px;
  margin-left: -4px;
  margin-top: -3px;
  width: auto;
}

.e-documenteditor .e-de-op-close-icon::before {
  content: '\e7fc';
}

.e-documenteditor .e-de-op-search-close-icon::before {
  content: '\e7fc';
  font-family: 'e-icons';
  font-size: 10px;
}

.e-de-table-properties-alignment:hover {
  border-color: #f17eb8;
}

.e-de-table-properties-alignment {
  border: 1px solid transparent;
}

.e-de-tablecell-alignment {
  border: 1px solid transparent;
}

.e-de-tablecell-alignment:hover {
  border-color: #f17eb8;
}

.e-de-table-left-alignment::before {
  content: '\e517';
  font-size: 46px;
}

.e-de-table-center-alignment::before {
  content: '\e518';
  font-size: 46px;
}

.e-de-table-right-alignment::before {
  content: '\e515';
  font-size: 46px;
}

.e-de-tablecell-top-alignment::before {
  content: '\e527';
  font-size: 51px;
}

.e-de-tablecell-center-alignment::before {
  content: '\e526';
  font-size: 51px;
}

.e-de-tablecell-bottom-alignment::before {
  content: '\e525';
  font-size: 51px;
}

.e-de-table-border-setting {
  border: 1px solid black;
  height: 48px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 48px;
}

.e-de-table-border-setting-genral {
  border: 1px solid rgba(0, 0, 0, 0.26);
  display: inline-block;
  height: 60px;
  width: 60px;
}

.e-de-table-border-preview-genral {
  border: 1px solid rgba(0, 0, 0, 0.26);
  display: inline-block;
  height: 25px;
  width: 25px;
}

.e-de-table-border-inside-setting:hover {
  border: 1px solid #f17eb8;
}

.e-de-table-border-preview {
  height: 24px;
  width: 24px;
}

.e-de-table-border-inside-preview:hover {
  border: 1px solid #f17eb8;
}

.e-de-table-border-inside-setting-click {
  border: 1px solid #ff4081;
}

.e-de-table-border-inside-preview-click {
  border: 1px solid #ff4081;
}

.e-de-table-border-none-setting::before {
  content: '\e507';
  font-size: 46px;
  position: absolute;
}

.e-de-table-border-box-setting::before {
  content: '\e509';
  font-size: 42px;
  left: 2px;
  position: absolute;
  top: 2px;
}

.e-de-table-border-all-setting::before {
  content: '\e511';
  font-size: 42px;
  left: 2px;
  position: absolute;
  top: 2px;
}

.e-de-table-border-custom-setting::before {
  content: '\e516';
  font-size: 42px;
  left: 2px;
  position: absolute;
  top: 2px;
}

.e-de-table-border-toptop-alignment::before {
  content: '\e281';
  font-size: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
}

.e-de-table-border-topcenter-alignment::before {
  content: '\e276';
  font-size: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
}

.e-de-table-border-topbottom-alignment::before {
  content: '\e298';
  font-size: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
}

.e-de-table-border-diagionalup-alignment::before {
  content: '\e262';
  font-size: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
}

.e-de-table-border-diagionaldown-alignment::before {
  content: '\e265';
  font-size: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
}

.e-de-table-border-bottomleft-alignment::before {
  content: '\e291';
  font-size: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
}

.e-de-table-border-bottomcenter-alignment::before {
  content: '\e287';
  font-size: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
}

.e-de-table-border-bottomright-alignment::before {
  content: '\e288';
  font-size: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
}

.e-menu-item .e-de-cut::before {
  content: '\e279';
}

.e-menu-item .e-de-spellcheck::before {
  content: '\e689';
}

.e-menu-item .e-de-copy::before {
  content: '\e280';
}

.e-icon-btn .e-de-paste::before,
.e-menu-item .e-de-paste::before {
  content: '\e501';
}

.e-menu-item .e-de-continue-numbering::before {
  content: '\e503';
}

.e-menu-item .e-de-restart-at::before {
  content: '\e277';
}

.e-menu-item .e-de-insertlink::before {
  content: '\e290';
}

.e-menu-item .e-de-open-hyperlink::before {
  content: '\e278';
}

.e-menu-item .e-de-copy-hyperlink::before {
  content: '\e295';
}

.e-menu-item .e-de-edit-hyperlink::before {
  content: '\e289';
}

.e-menu-item .e-de-remove-hyperlink::before {
  content: '\e286';
}

.e-menu-item .e-de-fonts::before {
  content: '\e273';
}

.e-menu-item .e-de-paragraph::before {
  content: '\e75e';
}

.e-menu-item .e-de-table::before {
  content: '\e294';
}

.e-menu-item .e-de-insertabove::before {
  content: '\e506';
}

.e-menu-item .e-de-insertbelow::before {
  content: '\e505';
}

.e-menu-item .e-de-insertleft::before {
  content: '\e285';
}

.e-menu-item .e-de-insertright::before {
  content: '\e284';
}

.e-menu-item .e-de-delete-table::before {
  content: '\e292';
}

.e-menu-item .e-de-deleterow::before {
  content: '\e283';
}

.e-menu-item .e-de-deletecolumn::before {
  content: '\e282';
}

.e-de-bold::before {
  content: '\e339';
  font-family: 'e-icons';
}

.e-de-italic::before {
  content: '\e35a';
  font-family: 'e-icons';
}

.e-de-underline::before {
  content: '\e343';
  font-family: 'e-icons';
}

.e-de-indent::before {
  content: '\e35d';
  font-family: 'e-icons';
}

.e-de-outdent::before {
  content: '\e33f';
  font-family: 'e-icons';
}

.e-de-align-left::before {
  content: '\e33a';
  font-family: 'e-icons';
}

.e-de-align-center::before {
  content: '\e35e';
  font-family: 'e-icons';
}

.e-de-align-right::before {
  content: '\e34d';
  font-family: 'e-icons';
}

.e-de-justify::before {
  content: '\e334';
  font-family: 'e-icons';
}

.e-de-single-spacing::before {
  content: '\e520';
  font-family: 'e-icons';
}

.e-de-double-spacing::before {
  content: '\e521';
  font-family: 'e-icons';
}

.e-de-one-point-five-spacing::before {
  content: '\e522';
  font-family: 'e-icons';
}

.e-de-before-spacing::before {
  content: '\e523';
  font-family: 'e-icons';
}

.e-de-after-spacing::before {
  content: '\e274';
  font-family: 'e-icons';
}

.e-de-icon-bullet-list-dot::before {
  content: '\e270';
  font-family: 'e-icons';
  font-size: 42px;
}

.e-de-icon-bullet-list-circle::before {
  content: '\e254';
  font-family: 'e-icons';
  font-size: 42px;
}

.e-de-icon-bullet-list-square::before {
  content: '\e271';
  font-family: 'e-icons';
  font-size: 42px;
}

.e-de-icon-bullet-list-tick::before {
  content: '\e259';
  font-family: 'e-icons';
  font-size: 42px;
}

.e-de-icon-bullet-list-flower::before {
  content: '\e267';
  font-family: 'e-icons';
  font-size: 42px;
}

.e-de-icon-bullet-list-arrow::before {
  content: '\e253';
  font-family: 'e-icons';
  font-size: 42px;
}

.e-de-icon-bullet-list-none::before {
  content: '\e256';
  font-family: 'e-icons';
  font-size: 42px;
}

.e-de-icon-autofit::before {
  content: '\eba8';
  font-family: 'e-icons';
}

.e-de-icon-fixed-columnwidth::before {
  content: '\eba9';
  font-family: 'e-icons';
}

.e-de-icon-auto-fitwindow::before {
  content: '\ebaa';
  font-family: 'e-icons';
}

.e-item .e-de-paste-text::before {
  content: '\e685';
}

.e-item .e-de-paste-source::before {
  content: '\e686';
}

.e-item .e-de-paste-merge::before {
  content: '\e688';
}

.e-de-blink-cursor {
  border-left: 1px solid;
  pointer-events: none;
  position: absolute;
  z-index: 3;
}

.e-de-cursor-animation {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: FadeInFadeOut;
}

@keyframes FadeInFadeOut {
  from {
    opacity: 1;
  }
  13% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  63% {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

.e-de-text-target {
  border: 0;
  height: 1px;
  opacity: 0;
  outline-style: none;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: -10000px;
  width: 625px;
}

.e-documenteditor .e-checkbox-wrapper .e-frame {
  height: 14px;
  line-height: 6px;
  width: 14px;
}

.e-documenteditor .e-checkbox-wrapper .e-label {
  font-size: 12px;
}

.e-documenteditor .e-de-op-close-button {
  height: 20px;
  left: 267px;
  position: absolute;
  top: 18px;
  width: 20px;
}

.e-documenteditor .e-de-op-close-button.e-de-rtl {
  left: 14px;
}

.e-de-background {
  background-color: #fff;
}

.e-de-result-list-block .e-de-search-result-hglt {
  background: transparent;
  border-bottom: 2px solid #bdbdbd;
  cursor: default;
  padding: 20px 1px 20px 5px;
}

.e-de-result-list-block .e-de-op-search-txt .e-de-op-search-word-text {
  color: #3f51b5;
}

.e-de-search-result-item {
  cursor: default;
  padding: 20px 1px 20px 5px;
  word-break: break-word;
}

.e-de-search-result-item:hover {
  border-bottom: 1px solid #bdbdbd;
  cursor: default;
}

.e-de-search-result-item:focus {
  border-bottom: 2px solid #bdbdbd;
  cursor: default;
  padding: 20px 1px 20px 5px;
}

.e-de-search-tab-content .e-input-group .e-de-op-search-icon:focus {
  border: 1px solid #ddd;
}

.e-de-op-search-icon:hover {
  background: #ddd;
}

.e-de-search-tab-content .e-input-group .e-de-op-search-close-icon:focus {
  border: 1px solid #ddd;
  border-right-width: none;
}

.e-de-op-search-close-icon:hover {
  background: #ddd;
}

.e-spin-down:focus {
  border: 1px solid #ddd;
  border-right-width: none;
}

.e-spin-down:hover {
  background: #ddd;
}

.e-spin-up:focus {
  border: 1px solid #ddd;
  border-right-width: none;
}

.e-spin-up:hover {
  background: #ddd;
}

.e-de-para-dlg-heading {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.e-de-para-dlg-container {
  height: auto;
  width: 400px;
}

.e-de-para-dlg-cs-check-box {
  margin-bottom: 8px;
  margin-top: 8px;
}

.e-de-para-dlg-spacing-div {
  margin-left: 40px;
}

.e-de-para-dlg-spacing-div.e-de-rtl {
  margin-left: 0;
  margin-right: 40px;
}

.e-de-toc-dlg-heading {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.e-de-toc-dlg-main-heading {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.e-content-placeholder.e-documenteditor.e-placeholder-documenteditor {
  background-size: 100%;
}

.e-de-toc-reset-button {
  margin-top: 10px;
}

.e-de-toc-reset-button.e-de-rtl {
  margin-right: 33px;
}

.e-de-toc-modify-button {
  margin-left: 156px;
  margin-top: 10px;
}

.e-de-toc-modify-button.e-de-rtl {
  margin-left: 0;
  margin-right: 174px;
}

.e-de-toc-dlg-container {
  height: 471px;
  width: 469px;
}

.e-de-toc-dlg-sub-container {
  margin-bottom: 20px;
}

.e-de-toc-list-view {
  border: 1px solid #808080;
  border-radius: 4px;
  font-size: 12px;
  height: 181px;
  margin-left: 23px;
  overflow-y: scroll;
  width: 211px;
}

.e-de-toc-list-view.e-de-rtl {
  margin-left: 0;
  margin-right: 23px;
}

.e-de-toc-dlg-sub-heading {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 15px 5px 15px;
}

.e-de-toc-dlg-style-label {
  position: absolute;
  top: 60px;
}

.e-de-pagesetup-dlg-container {
  height: 246px;
  width: 356px;
}

.e-de-page-setup-ppty-tab {
  border: 0;
}

.e-de-page-setup-dlg-sub-container {
  margin-bottom: 25px;
}

.e-de-page-setup-dlg-left-sub-container {
  float: left;
  position: relative;
  top: 17px;
}

.e-de-page-setup-dlg-left-sub-container.e-de-rtl {
  float: right;
}

.e-de-page-setup-dlg-right-sub-container {
  float: right;
  position: relative;
  top: 17px;
}

.e-de-page-setup-dlg-right-sub-container.e-de-rtl {
  float: left;
}

.e-de-page-setup-dlg-sub-header {
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 3px;
  margin-top: 8px;
}

.e-de-page-setup-dlg-sub-title-header {
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 3px;
  margin-top: 12px;
}

.e-de-page-setup-dlg-sub-container-port {
  height: 40px;
  margin-bottom: 25px;
}

.e-de-page-setup-dlg-sub-label {
  font-size: 13px;
  font-weight: 450;
}

.e-de-page-setup-dlg-orientation-prop {
  margin-top: 13px;
}

.e-de-page-setup-dlg-sub-size-container {
  height: 65px;
  margin-bottom: 20px;
}

.e-de-page-setup-dlg-layout-sub-container {
  height: 60px;
  margin-bottom: 20px;
  position: relative;
  top: 17px;
}

.e-de-page-setup-dlg-first-page-prop .e-label,
.e-de-page-setup-dlg-odd-or-even-prop .e-label {
  font-size: 12px;
}

.e-de-page-setup-dlg-first-page-prop .e-frame,
.e-de-page-setup-dlg-odd-or-even-prop .e-frame {
  height: 18px;
  line-height: 10px;
  width: 18px;
}

.e-de-page-setup-dlg-left-layout-container {
  float: left;
  position: relative;
  top: 10px;
}

.e-de-page-setup-dlg-left-layout-container.e-de-rtl {
  float: right;
}

.e-de-page-setup-dlg-right-layout-container {
  float: right;
  position: relative;
  top: 10px;
}

.e-de-page-setup-dlg-right-layout-container.e-de-rtl {
  float: left;
}

.e-de-dlg-sub-header {
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 15px;
  margin-top: 8px;
}

.e-de-para-dlg-sub-container .e-input-group {
  margin-bottom: 15px;
}

.e-de-para-dlg-sub-container {
  margin-bottom: 14px;
}

.e-de-para-dlg-right-sub-container {
  top: 17px;
}

.e-de-dlg-footer .e-btn {
  margin-left: 10px;
}

.e-de-hyperlink-dlg-title {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 8px;
}

.e-de-hyperlink .e-de-hyperlink-dlg-input {
  height: 16px;
  margin-bottom: 20px;
  width: 230px;
}

.e-de-font-dlg-header {
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 8px;
}

.e-de-font-dlg-header-effects,
.e-de-font-dlg-header-font-color {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 8px;
}

.e-de-font-dlg-main-header {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: normal;
  margin-right: 15px;
}

.e-de-font-dlg-cb-right {
  margin-left: 40px;
}

.e-de-font-dlg-cb-right.e-de-rtl {
  margin-left: 0;
  margin-right: 40px;
}

.e-de-font-dlg-cb-right-div {
  margin-left: 20px;
}

.e-de-dropdown {
  margin-right: 40px;
}

.e-de-restrict-pane,
.e-de-op {
  border-right: 1px solid #e0e0e0;
  padding-left: 16px;
  padding-top: 24px;
  position: relative;
  width: 300px;
}

.e-de-op.e-de-rtl {
  padding-left: 0;
  padding-right: 16px;
}

.e-de-op-header {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}

.e-de-op-header.e-de-rtl {
  direction: rtl;
}

.e-de-op-tab {
  border: 0;
  height: 36px;
}

.e-de-op-icon {
  color: rgba(0, 0, 0, 0.54);
  height: 20px;
  width: 20px;
}

.e-de-op-close-icon {
  color: rgba(0, 0, 0, 0.54);
}

.e-de-op-nav-btn {
  height: 20px;
  width: 20px;
}

.e-de-op-search-txt {
  border-bottom: 1px solid #ddd;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}

.e-de-op-search-txt .e-de-op-search-word {
  color: #3f51b5;
}

.e-de-op-more-less {
  display: block;
  margin-top: 14px;
}

.e-de-op-replacetabcontentdiv {
  height: 82px;
  margin-top: 14px;
}

label[for='container_wholeWord_e-de-ltr'] {
  left: 35px;
}

label[for='container_wholeWord_e-de-rtl'] {
  right: 35px;
}

label[for='container_editor_wholeWord_e-de-ltr'] {
  left: 35px;
}

label[for='container_editor_wholeWord_e-de-rtl'] {
  right: 35px;
}

.e-de-cell-dia-label-common {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 150px;
}

.e-de-cell-dia-options-label {
  font-size: 14px;
  font-weight: 400;
}

.e-de-table-border-heading {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 20px;
}

.e-de-table-setting-heading {
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 20px;
}

.e-de-layout-setting-heading {
  font-size: 14px;
  font-weight: normal;
  padding-bottom: 20px;
}

.e-de-table-setting-labels-heading {
  font-size: 12px;
  font-weight: normal;
}

.e-de-table-element-subheading {
  font-size: 12px;
  font-weight: normal;
}

.e-de-border-dlg-preview-div {
  border: 1px solid rgba(0, 0, 0, 0.54);
}

.e-de-border-dlg-preview-inside-divs {
  opacity: 0.54;
}

.e-de-table-dia-align-div {
  border: 1px solid #ddd;
  display: inline-block;
  height: 60px;
  margin-right: 10px;
  width: 60px;
}

.e-de-table-dia-align-div.e-de-rtl {
  margin-left: 10px;
  margin-right: 0;
}

.e-de-table-dia-align-label {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  margin-left: 10px;
  margin-top: 8px;
}

.e-de-table-dialog-separator-line {
  background-color: #e0e0e0;
  bottom: 50px;
  display: none;
  height: 1px;
  left: 1px;
  margin-top: 0;
  position: absolute;
  width: 100%;
}

.e-de-table-alignment-active {
  border: 1px solid #ff4081;
}

.e-de-table-dialog-options-label {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 15px;
  padding-top: 20px;
}

.e-de-list-ddl-header {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 20px;
  margin-top: 20px;
}

.e-de-list-ddl-header-list-level {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 20px;
}

.e-de-tbl-dlg-footer {
  padding-top: 27px;
}

.e-de-row-ht-top {
  padding: 0 20px;
}

.e-de-ht-wdth-type {
  margin-top: -22px;
}

.e-de-row-ht-top.e-de-rtl {
  margin-left: 0;
  margin-right: 20px;
}

.e-de-cell-width-top {
  margin-left: 20px;
  margin-top: -23px;
}

.e-de-cell-width-top.e-de-rtl {
  margin-left: 0;
  margin-right: 20px;
}

.e-de-tbl-dlg-border-btn {
  float: right;
  margin-top: 12px;
}

.e-de-tbl-dlg-border-btn.e-de-rtl {
  margin-right: 0;
}

.e-de-table-border-setting.e-de-rtl {
  right: 5px;
}

.e-de-tbl-dlg-op-btn {
  left: 425px;
  position: absolute;
  top: 300px;
}

.e-de-insert-table-dlg-sub-header {
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 8px;
  margin-top: 8px;
}

.e-de-insert-table-dlg-input {
  margin-bottom: 15px;
}

.e-de-list-ddl-subheader,
.e-de-list-ddl-subheaderbottom {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 10px;
  margin-top: 10px;
}

.e-de-list-dlg-subdiv {
  float: right;
  margin-top: 51px;
  position: relative;
}

.e-de-list-dlg-subdiv.e-de-rtl {
  float: left;
  margin-top: -138px;
}

.e-de-list-dlg-div {
  float: right;
  margin-top: 46px;
  position: relative;
}

.e-de-list-dlg-div.e-de-rtl {
  float: left;
  margin-top: -139px;
}

.e-de-ok-button {
  margin-right: 10px;
}

.e-de-ok-button.e-de-rtl {
  margin-left: 10px;
}

.e-de-options-setter {
  left: 415px;
}

.e-de-op-close-icon:hover {
  color: rgba(0, 0, 0, 0.54);
}

.e-de-tooltip {
  background-color: #fff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.26);
  cursor: text;
  max-width: 200px;
  padding: 5px;
  width: -webkit-fit-content;
  width: fit-content;
  word-wrap: break-word;
}

.e-btn.e-de-op-icon-btn {
  background-color: none;
  border-color: none;
}

.e-documenteditor .e-de-op-close-button {
  height: 20px;
  left: 267px;
  position: absolute;
  top: 18px;
  width: 20px;
}

.e-documenteditor .e-de-op-close-button.e-de-rtl {
  right: 267px;
}

.e-btn.e-de-op-close-button:hover {
  background-color: none;
  border-color: none;
  color: rgba(0, 0, 0, 0.54);
}

.e-btn.e-de-op-close-button:focus {
  background-color: none;
  border-color: none;
  color: rgba(0, 0, 0, 0.54);
}

.e-btn.e-de-op-close-button:active {
  background-color: none;
  border-color: none;
  color: rgba(0, 0, 0, 0.54);
}

.e-documenteditor .e-input {
  color: #000;
  font-size: 14px;
}

.e-de-dlg-target .e-footer-content .e-control.e-btn.e-flat:not(.e-icon-btn) {
  height: 36px;
}

.e-de-tbl-dlg-border-btn .e-control.e-btn.e-flat:not(.e-icon-btn) {
  height: auto;
}

.e-de-op-result-container {
  margin-top: 12px;
}

.e-de-restrict-pane,
.e-de-op {
  background-color: #fff;
}

.e-de-restrict-pane .e-tab-header .e-toolbar-items,
.e-de-op .e-tab-header .e-toolbar-items {
  margin-bottom: 20px;
  margin-top: 1px;
}

.e-de-font-dlg-color {
  border: 1px #ddd;
  border-radius: 4px;
  font-size: 12px;
  height: 16px;
  margin-left: 15px;
  width: 25px;
}

.e-de-icon-table-row-above {
  top: 7px;
}

.e-de-icon-table-row-below {
  top: 41px;
}

.e-de-icon-table-column-left {
  top: 75px;
}

.e-de-icon-table-column-right {
  top: 109px;
}

.e-de-icon-table-delete {
  top: 7px;
}

.e-de-icon-table-row-delete {
  top: 41px;
}

.e-de-icon-table-column-delete {
  top: 75px;
}

.e-de-list-bullet-none {
  height: 40px;
  width: 40px;
}

.e-de-list-bullet-dot {
  height: 40px;
  width: 40px;
}

.e-de-list-bullet-circle {
  height: 40px;
  width: 40px;
}

.e-de-list-bullet-square {
  height: 40px;
  width: 40px;
}

.e-de-list-bullet-flower {
  height: 40px;
  width: 40px;
}

.e-de-list-bullet-arrow {
  height: 40px;
  width: 40px;
}

.e-de-list-bullet-tick {
  height: 40px;
  width: 40px;
}

.e-de-bullet:hover {
  background: #ddd;
}

.e-de-list-numbered-none {
  height: 80px;
  width: 80px;
}

.e-de-list-numbered-number-dot {
  height: 80px;
  width: 80px;
}

.e-de-list-numbered-number-brace {
  height: 80px;
  width: 80px;
}

.e-de-list-numbered-up-roman {
  height: 80px;
  width: 80px;
}

.e-de-list-numbered-up-letter {
  height: 80px;
  width: 80px;
}

.e-de-list-numbered-low-letter-brace {
  height: 80px;
  width: 80px;
}

.e-de-numbered-low-letter-dot {
  height: 80px;
  width: 80px;
}

.e-de-list-numbered-low-roman {
  height: 80px;
  width: 80px;
}

.e-de-numbered:hover {
  background: #ddd;
}

.e-de-list-multilevel-none {
  height: 80px;
  width: 80px;
}

.e-de-list-multilevel-list-normal {
  height: 80px;
  width: 80px;
}

.e-de-list-multilevel-list-multilevel {
  height: 80px;
  width: 80px;
}

.e-de-list-multilevel-list-bullets {
  height: 80px;
  width: 80px;
}

.e-de-multilevel-list:hover {
  background: #ddd;
}

.e-de-list-dialog-open:hover {
  background: #ddd;
}

.e-de-cell-options {
  left: 339px;
  top: 290px;
}

.e-de-cell-options.e-de-rtl {
  left: 105px;
}

.e-de-font-color-label {
  margin-bottom: 7px;
  margin-top: 18px;
}

.e-de-font-content-label {
  width: 115px;
}

.e-de-font-color-margin {
  margin-right: 10px;
  margin-top: 6px;
}

.e-de-font-color-margin.e-de-rtl {
  margin-left: 10px;
  margin-right: 0;
}

.e-de-font-content-checkbox-label {
  margin-left: 38px;
}

.e-de-font-content-checkbox-label-rtl {
  margin-right: 47px;
}

.e-bigger .e-de-font-content-checkbox-label {
  margin-left: 60px;
}

.e-bigger .e-de-font-content-checkbox-label-rtl {
  margin-right: 60px;
}

.e-bigger .e-de-font-content-label {
  width: 132px;
}

.e-de-font-checkbox {
  margin-left: 58px;
}

.e-de-font-checkbox.e-de-rtl {
  margin-left: 0;
  margin-right: 58px;
}

.e-de-font-checkbox.e-de-rtl {
  margin-left: 0;
  margin-right: 58px;
}

.e-de-font-dlg-padding {
  margin-top: 15px;
}

.e-de-table-container-div {
  margin-top: 20px;
}

.e-de-table-header-div {
  padding-top: 6px;
}

.e-de-table-subheader-div {
  float: right;
  margin-right: 115px;
  margin-top: -40px;
}

.e-de-table-subheader-div.e-de-rtl {
  float: left;
  margin-left: 115px;
  margin-right: 0;
}

.e-de-table-cell-header-div {
  padding-top: 6px;
}

.e-de-table-cell-subheader-div {
  top: 57px;
}

.e-de-cell-margin-header {
  left: -7px;
  top: 310px;
}

.e-de-font-dlg-display {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-de-tbl-margin-sub-header {
  margin-top: 20px;
}

.e-de-tbl-btn-seperator {
  width: 57%;
}

.e-de-op-msg {
  color: #000;
  top: 79px;
}

.e-de-save-dlg-file-name {
  height: 25px;
  margin-bottom: 8px;
}

.e-de-save-dlg-format-type {
  height: 25px;
  margin-bottom: 8px;
  padding-top: 10px;
}

.e-de-search-tab-content {
  margin-right: 1px;
  margin-top: 1px;
  width: 267px;
}

.e-de-font-dlg {
  width: 380px;
}

.e-de-hyperlink {
  height: 175px;
  width: 230px;
}

.e-de-insert-table {
  height: 131px;
  width: 230px;
}

.e-de-insert-spellchecker {
  height: 350px;
  width: 513px;
}

.e-de-dlg-spellcheck-listview {
  border: 1px solid #e4e4e4;
  border-radius: 2px !important;
  height: 132px !important;
  margin-top: 8px;
  position: relative;
  float: left;
  width: 343px;
}

.e-de-spellcheck-error-container {
  height: 140px;
  margin-bottom: 25px;
}

.e-de-spellcheck-suggestion-container {
  height: 140px;
}

.e-dlg-spellcheck-listitem {
  font-size: 15px !important;
}

.e-de-spellcheck-btncontainer {
  margin-top: 8px;
  position: relative;
  width: 154px;
  float: right;
}

.e-de-spellcheck-btn {
  height: 36px;
  margin-bottom: 8px;
  width: 154px;
}

.e-de-dlg-spellchecker-subheader {
  font-size: 15px;
  margin-top: 8px;
}

.e-de-dlg-spellchecker-subheaderbtm {
  font-size: 15px;
}

.e-de-list-dlg {
  height: 400px;
  width: 420px;
}

.e-de-save-dlg {
  height: 135px;
  width: 230px;
}

.e-de-table-properties-dlg {
  width: 426px;
}

.e-de-table-border-shading-dlg {
  height: 415px;
  width: 450px;
}

.e-de-table-cell-margin-dlg {
  height: 222px;
  width: 342px;
}

.e-de-table-options-dlg {
  height: 242px;
  width: 380px;
}

.e-de-table-border-none {
  position: absolute;
  top: 25px;
}

.e-de-table-border-box {
  position: absolute;
  top: 95px;
}

.e-de-table-border-all {
  position: absolute;
  top: 165px;
}

.e-de-table-border-custom {
  position: absolute;
  top: 235px;
}

.e-de-table-shading-preview {
  top: 385px;
}

.e-de-font-content-label span.e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-font-label span.e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-font-content-label:hover .e-label,
.e-css.e-de-font-content-label:hover .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-font-label:hover .e-label,
.e-css.e-de-font-label:hover .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-documenteditor .e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}

.e-documenteditor .e-checkbox-wrapper .e-frame {
  height: 18px;
  line-height: 10px;
  width: 18px;
}

.e-de-op-dlg-footer {
  margin-top: 15px;
}

.e-de-op-dlg-footer .e-btn {
  padding-left: 6px;
  padding-right: 6px;
}

.e-de-search-tab-content .e-input-group .e-de-search-input {
  width: 185px;
}

.e-de-op-replacewith {
  width: 95%;
}

.e-de-table-ppty-tab {
  border: 0;
}

.e-de-table-container-div .e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-table-header-div .e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-table-ppty-options-break .e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-table-ppty-options-header-row .e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-table-cell-header-div .e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-tbl-margin-sub-header .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-tbl-btn-seperator .e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-de-list-format-info {
  border-radius: 50%;
  cursor: default;
  font-size: 12px;
  height: 15px;
  line-height: 1px;
  padding: 3px 0 0 0;
  text-transform: lowercase;
  width: 16px;
}

.e-button-custom {
  height: 34px;
  padding: 0;
  width: 80px;
}

.e-bigger .e-button-custom {
  height: 37px;
  width: 86px;
}

.e-styles-listview,
.e-bookmark-listview {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  height: 150px;
  overflow-y: scroll;
}

.e-bookmark-gotobutton,
.e-bookmark-addbutton,
.e-styles-addbutton,
.e-bookmark-deletebutton {
  margin-bottom: 8px;
}

.e-bookmark-list {
  float: left;
  margin-right: 20px;
  width: 250px;
}

.e-bookmark-list.e-de-rtl {
  margin-left: 20px;
  margin-right: 0;
}

.e-bookmark-textboxdiv {
  margin-bottom: 10px;
}

.e-bookmark-listview .e-list-item {
  font-size: 13px;
  height: 30px;
  line-height: 27px;
}

.e-bookmark-common {
  display: -ms-flexbox;
  display: flex;
}

.e-bookmark-button {
  position: relative;
  top: 15px;
}

.e-font {
  float: left;
}

.e-font-rtl {
  float: right;
}

.e-de-table-border-toptop-alignment,
.e-de-table-border-topcenter-alignment,
.e-de-table-border-topbottom-alignment,
.e-de-table-border-diagionalup-alignment,
.e-de-table-border-diagionaldown-alignment,
.e-de-table-border-bottomleft-alignment,
.e-de-table-border-bottomcenter-alignment,
.e-de-table-border-bottomright-alignment {
  left: 48%;
  position: absolute;
  top: 48%;
  transform: translate(-50%, -50%);
}

.e-de-style-properties,
.e-de-style-formatting {
  font-size: 14px;
  font-weight: 400;
}

.e-de-style-formatting {
  margin-bottom: 20px;
}

.e-de-style-paragraph-indent-group-button .e-btn,
.e-de-style-paragraph-group-button .e-btn,
.e-de-style-font-group-button .e-btn {
  box-shadow: none;
}

.e-de-style-paragraph-indent-group-button .e-btn.e-active,
.e-de-style-paragraph-group-button .e-btn.e-active,
.e-de-style-font-group-button .e-btn.e-active {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  box-shadow: none;
  color: #000;
}

.e-de-style-properties {
  margin-bottom: 15px;
}

.e-de-style-nametype-div {
  margin-bottom: 20px;
}

.e-de-style-based-para-div {
  margin-bottom: 30px;
}

.e-de-style-name,
.e-de-style-styletype,
.e-de-style-style-based-on,
.e-de-style-style-paragraph {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
  width: 180px;
}

.e-de-style-left-div {
  margin-right: 24px;
}

.e-de-style-left-div.e-de-rtl {
  margin-left: 24px;
  margin-right: 0;
}

.e-de-style-font-color-picker,
.e-de-style-icon-button-size,
.e-de-style-icon-button-first-size,
.e-de-style-icon-button-last-size {
  height: 31px;
}

.e-bigger .e-de-style-font-color-picker,
.e-bigger .e-de-style-icon-button-size,
.e-bigger .e-de-style-icon-button-first-size,
.e-bigger .e-de-style-icon-button-last-size {
  height: 35px;
}

.e-bigger .e-de-style-bold-button-size {
  height: 35px;
  margin-left: 6px;
  margin-right: 8px;
}

.e-de-style-bold-button-size {
  height: 31px;
  margin-left: 6px;
  margin-right: 8px;
}

.e-de-style-format-dropdwn .e-btn-icon {
  margin-left: 8px;
}

.e-de-style-font-color-picker,
.e-de-style-icon-button-size {
  margin-right: 8px;
}

.e-de-style-icon-button-first-size {
  margin-left: 6px;
  margin-right: 3px;
}

.e-de-style-icon-button-last-size {
  margin-right: 6px;
}

.e-de-style-font-color-picker {
  margin-left: 8px;
}

.e-style-font-fmaily-right {
  margin-right: 8px;
}

.e-style-font {
  margin-left: 5px;
  margin-right: 15px;
}

.e-de-style-left-div .e-de-style-dlg-name-input {
  height: 18px;
}

.e-style-list {
  margin-left: 10px;
}

.e-de-style-dialog .e-de-style-only-this-document {
  margin-top: 25px;
}

.e-de-style-format-dropdwn {
  width: 135px;
}

.e-de-style-options-div {
  margin-bottom: 20px;
}

.e-de-style-paragraph-group-button {
  border-right: 2px solid #e0e0e0;
}

.e-de-style-font-group-button {
  border-left: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
}

.e-de-op-replace-messagediv {
  color: #000;
  position: absolute;
  top: 144px;
}

.e-de-font-content-label .e-label,
.e-de-font-dlg-cb-right .e-label,
.e-de-font-checkbox .e-label {
  font-size: 12px;
}

.e-de-font-content-label .e-frame,
.e-de-font-dlg-cb-right .e-frame,
.e-de-font-checkbox .e-frame {
  height: 18px;
  line-height: 10px;
  width: 18px;
}

.e-de-op-input-group,
.e-de-op-replacewith {
  height: 32px;
}

.e-bigger .e-de-op-input-group,
.e-bigger .e-de-op-replacewith {
  height: 40px;
}

.e-de-hyperlink-bookmark-check {
  margin-top: 20px;
}

.e-de-table-container-div .e-checkbox-wrapper .e-frame,
.e-de-table-header-div .e-checkbox-wrapper .e-frame,
.e-de-table-ppty-options-break .e-checkbox-wrapper .e-frame,
.e-de-table-ppty-options-header-row .e-checkbox-wrapper .e-frame,
.e-de-table-cell-header-div .e-checkbox-wrapper .e-frame,
.e-de-tbl-btn-seperator .e-checkbox-wrapper .e-frame,
.e-de-hyperlink-bookmark-check .e-checkbox-wrapper .e-frame,
.e-de-tbl-margin-sub-header .e-frame {
  height: 18px;
  line-height: 10px;
  width: 18px;
}

.e-de-table-container-div .e-checkbox-wrapper .e-label,
.e-de-table-header-div .e-checkbox-wrapper .e-label,
.e-de-table-ppty-options-break .e-checkbox-wrapper .e-label,
.e-de-table-ppty-options-header-row .e-checkbox-wrapper .e-label,
.e-de-table-cell-header-div .e-checkbox-wrapper .e-label,
.e-de-tbl-btn-seperator .e-checkbox-wrapper .e-label,
.e-de-hyperlink-bookmark-check .e-checkbox-wrapper .e-label,
.e-de-tbl-margin-sub-header .e-label {
  font-size: 14px;
}

.e-de-table-ppty-dlg-measure-div {
  float: right;
  margin-left: 40px;
  margin-top: -18px;
}

.e-de-table-ppty-dlg-measure-div.e-de-rtl {
  float: left;
  margin-left: 0;
  margin-right: 40px;
}

.e-de-table-ppty-dlg-measure-drop-down-div {
  float: right;
  margin-left: 40px;
  margin-top: 6px;
}

.e-de-table-ppty-dlg-measure-drop-down-div.e-de-rtl {
  margin-left: 0;
  margin-right: 40px;
}

.e-de-table-ppty-dlg-left-indent-container {
  bottom: 0;
  left: 69px;
  position: relative;
}

.e-de-table-ppty-dlg-left-indent-container.e-de-rtl {
  right: 69px;
}

.e-de-table-ppty-dlg-row-height-label {
  float: right;
  margin-right: 161px;
  margin-top: -62px;
}

.e-de-table-ppty-dlg-row-height-label.e-de-rtl {
  float: left;
  margin-left: 161px;
  margin-right: 0;
}

.e-de-table-ppty-dlg-preferred-width-div {
  float: right;
  margin-left: 20px;
  margin-top: 6px;
}

.e-de-table-ppty-dlg-preferred-width-div.e-de-rtl {
  margin-left: 0;
  margin-right: 20px;
}

.e-de-table-ppty-options-break {
  margin-bottom: 15px;
}

.e-de-table-cell-subheader-div {
  margin-right: 105px;
  margin-top: -40px;
}

.e-de-table-cell-subheader-div.e-de-rtl {
  margin-left: 105px;
  margin-right: 0;
}

.e-de-table-ppty-dlg-cell-tab-measure-label {
  float: right;
  margin-right: 172px;
  margin-top: -64px;
}

.e-tab .e-tab-header .e-toolbar-item .e-de-table-ppty-dlg-row-header {
  padding-left: 24px;
  padding-right: 24px;
}

.e-tab .e-tab-header .e-toolbar-item .e-de-table-ppty-dlg-cell-header {
  padding-left: 24px;
  padding-right: 24px;
}

.e-tab .e-tab-header .e-toolbar-item .e-de-page-setup-dlg-margin-tab-header {
  padding-left: 1px;
  padding-right: 24px;
}

.e-styles-list {
  float: left;
  margin-right: 20px;
  width: 250px;
}

.e-styles-textboxdiv {
  padding-bottom: 15px;
}

.e-styles-listview .e-list-item {
  font-size: 13px;
  height: 30px;
  line-height: 27px;
}

.e-styles-common {
  padding-top: 5px;
}

.e-styles-button {
  float: right;
}

.e-de-toc-dlg-right-sub-container {
  margin-right: 2px;
  margin-top: 119px;
}

.e-de-toc-dlg-right-sub-container.e-de-rtl {
  margin-left: 2px;
  margin-right: 0;
}

.e-de-toc-dlg-right-sub-container.e-de-rtl {
  margin-right: 2px;
}

.e-de-toc-dlg-styles {
  margin-bottom: 11px;
  margin-left: 25px;
  margin-top: 38px;
}

.e-de-toc-dlg-styles.e-de-rtl {
  margin-left: 0;
  margin-right: 25px;
}

.e-de-toc-dlg-build-table {
  margin-top: 45px;
}

.e-de-toc-table-div .e-de-toc-dlg-toc-level {
  height: 24px;
  margin-left: 36px;
  width: 44px;
}

.e-de-toc-styles-table-div {
  border: 1px solid #808080;
  border-radius: 4px;
  margin-top: 15px;
  width: 213px;
}

.e-de-toc-dlg-sub-level-heading {
  font-size: 12px;
}

.e-de-toc-dlg-show-level-div {
  margin-left: 255px;
  margin-top: 16px;
}

.e-de-toc-dlg-show-level-div.e-de-rtl {
  margin-left: 0;
  margin-right: 255px;
}

.e-de-toc-table-div {
  height: 163px;
  overflow-y: scroll;
  width: 211px;
}

.e-de-toc-dlg-style-input {
  margin-bottom: 3px;
  margin-left: 23px;
  width: 210px;
}

.e-de-toc-dlg-outline-levels {
  margin-top: 10px;
  width: 150px;
}

.e-bookmark-textboxdiv .e-bookmark-textbox-input {
  height: 34px;
}

.e-styles-dlgfields {
  font-weight: 400;
  margin-bottom: 6px;
}

.e-de-toc-dlg-showlevel-div {
  margin-left: 255px;
}

.e-tab .e-tab-header .e-toolbar-item .e-de-op-find-tab-header {
  padding-left: 1px;
  padding-right: 0 24px;
}

.e-tab .e-tab-header .e-toolbar-item .e-de-op-replace-tab-header {
  padding-left: 0 24px;
  padding-right: 0 24px;
}

.e-de-dlg-target .e-footer-content .e-list-dlg-font {
  margin-left: 16px;
}

.e-bookmark-dlgfields {
  font-weight: 400;
  margin-bottom: 0;
}

.e-de-ui-wfloating-menu.e-de-ui-bullets-menu .e-de-ui-wfloating-menuitem-md {
  height: 65px;
  padding: 10px;
  width: 70px;
}

.e-de-ui-wfloating-menu.e-de-ui-bullets-menu .e-de-ui-wfloating-bullet-menuitem-md {
  height: 45px;
  width: 45px;
}

.e-de-bullet-icon-size {
  height: 45px;
  width: 45px;
}

.e-de-ui-list-header-presetmenu {
  cursor: pointer;
  font-size: 11px;
  line-height: 14px;
  min-width: 50px;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

.e-de-ui-bullet {
  font-size: 42px;
}

.e-de-ui-list-header-presetmenu .e-de-ui-list-line {
  border-bottom: 1px solid #ccc;
  margin-left: 5px;
  width: 100%;
}

.e-de-ui-list-header-presetmenu div span {
  color: #aaa;
  display: inline-block;
  vertical-align: middle;
}

.e-de-ui-wfloating-menu .e-de-ui-wfloating-menuitem,
.e-de-ui-wfloating-menu .e-de-ui-menuitem-none {
  border: 0;
  box-shadow: inset 0 0 0 1px #ebebeb;
  cursor: pointer;
  height: 70px;
  margin: 0 5px 5px 0;
  padding: 0;
  width: 70px;
}

.e-de-ui-wfloating-menu {
  padding: 10px 4px 5px 10px;
}

.e-de-list-thumbnail .e-de-list-items {
  float: left;
}

.e-de-list-thumbnail .e-de-list-items {
  background: #fff;
  border: 1px solid transparent;
  clear: initial;
  display: inline-block;
  height: auto;
  margin: 5px;
  text-align: center;
  width: auto;
}

.e-de-list-items {
  background: #fff;
  box-sizing: border-box;
  cursor: pointer;
  list-style: none;
  padding: 7px 10px;
  position: relative;
}

.e-de-list-item-size {
  font-size: 14px;
}

.e-de-ui-wfloating-menu {
  padding: 10px 4px 5px 10px;
}

.e-de-table-border-fill.e-de-rtl {
  margin-left: 15px;
}

.e-de-table-border-fill:not(.e-de-rtl) {
  margin-right: 15px;
  margin-top: 5px;
}

.e-de-table-ppty-dlg-tabs {
  height: 320px;
  position: relative;
}

.e-de-ui-bullet-list-header-presetmenu .e-de-list-thumbnail .e-de-list-active,
.e-de-style-numbered-list .e-de-list-thumbnail .e-de-list-active {
  border-color: #ff4081;
}

.e-de-bullet-icons {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-43%, -43%);
}

.e-de-header-footer-list {
  color: #3f51b5;
}

.e-de-rtl-btn-div {
  font-size: 12px;
  margin-right: 14px;
  width: 200px;
}

.e-de-rtl-btn-div.e-de-rtl {
  margin-left: 14px;
  margin-right: 0;
}

.e-de-ltr-btn-div {
  font-size: 12px;
  width: 200px;
}

.e-de-tbl-rtl-btn-div {
  font-size: 12px;
  margin-right: 14px;
  width: 140px;
}

.e-de-tbl-rtl-btn-div.e-de-rtl {
  margin-left: 14px;
  margin-right: 0;
}

.e-de-tbl-ltr-btn-div {
  font-size: 12px;
  width: 140px;
}

.e-para-dlg-sub-height {
  height: 145px;
}

.e-de-disabledbutton {
  opacity: 0.4;
  pointer-events: none;
}

.e-bigger .e-de-insert-table {
  height: auto;
  width: auto;
}

.e-bigger .e-de-dlg-target .e-footer-content .e-control.e-btn.e-flat:not(.e-icon-btn) {
  height: auto;
}

.e-bigger .e-de-font-dlg {
  width: 375px;
}

.e-bigger .e-para-dlg-sub-height {
  height: 170px;
}

.e-bigger .e-de-toc-table-div .e-de-toc-dlg-toc-level.e-de-rtl {
  margin-right: 36px;
}

.e-bigger .e-de-font-content-label-width {
  width: 72px;
}

.e-bigger .e-de-toc-label {
  margin-left: 194px;
}

.e-bigger .e-de-toc-label-rtl {
  margin-left: 0;
  margin-right: 180px;
}

.e-bigger .e-de-outline-rtl {
  width: 173px;
}

.e-de-restrict-format {
  margin-top: 16px;
}

.e-de-rp-format {
  font-size: 13px;
  margin-bottom: 12px;
  opacity: .65;
}

.e-de-rp-checkbox {
  font-size: 12px;
}

.e-de-rp-border {
  margin-bottom: 12px;
  margin-top: 12px;
}

.e-de-rp-header {
  font-size: 15px;
  font-weight: 500;
  opacity: .87;
}

.e-de-restrict-pane .e-checkbox-wrapper .e-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
}

.e-de-restrict-pane .e-checkbox-wrapper .e-frame {
  height: 16px;
  width: 16px;
}

.e-de-restrict-pane .e-checkbox-wrapper {
  margin-bottom: 8px;
}

.e-de-rp-user .e-checkbox-wrapper {
  width: auto;
}

.e-de-rp-nav-btn,
.e-de-rp-btn-enforce {
  background: #fafafa;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 13px;
  height: 36px;
  opacity: .87;
}

.e-de-rp-nav-btn {
  margin: 0 12px;
  width: 210px;
}

.e-de-rp-btn-stop-enforce {
  background: #fafafa;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 13px;
  height: 36px;
  margin: 0 46px;
  opacity: .87;
}

.e-de-rp-nav-lbl {
  font-size: 13px;
  margin: 0 28px;
}

.e-de-rp-sub-div {
  border-bottom: 1px solid #e4e4e4;
  padding: 12px;
}

.e-de-restrict-pane {
  padding-left: 0;
  padding-top: 0;
}

.e-de-rp-whole-header {
  padding: 12px;
}

.e-de-rp-user {
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  font-size: 12px;
  height: 110px;
  width: 238px;
}

.e-de-rp-enforce {
  padding-top: 12px;
}

.e-de-rp-enforce-nav {
  margin: 12px 0;
}

.e-de-enforce-dlg-title {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 8px;
}

.e-de-enforce .e-de-enforce-dlg-input {
  height: 16px;
  margin-bottom: 8px;
  width: 300px;
}

.e-de-user-add-btn {
  background: #e0e0e0;
  border-radius: 2px;
  width: 74px;
}

.e-de-user-dlg .e-de-user-dlg-textbox-input {
  margin-right: 16px;
  width: 304px;
}

.e-de-user-dlg-list {
  margin-bottom: 15px;
}

.e-de-user-listview {
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  height: 106px;
}

.e-de-user-dlg-user {
  margin-bottom: 12px;
}

.e-user-delete {
  float: left;
}

.e-de-unprotect-dlg-title {
  font-size: 14px;
  margin-bottom: 8px;
}

.e-de-rp-stop-div1 {
  opacity: .87;
  padding: 12px 12px 6px 12px;
  font-weight: 500;
  border-bottom: 1px solid #E0E0E0;
}

.e-de-rp-stop-div2 {
  padding: 12px 12px 24px;
}

.e-de-rp-stop-div3 {
  padding: 0 0 12px 12px;
}

.e-de-rp-close-icon {
  float: right;
  position: relative;
  top: -7px;
}

.e-de-restrict-pane {
  height: 100%;
  overflow: auto;
  width: 268px;
}

.e-de-rp-nav-lbl {
  font-size: 13px;
  margin: 0 28px;
}

.e-documenteditor-optionspane {
  height: 100%;
}

.e-de-ctnr-close::before {
  content: '\eb36';
}

.e-de-ctnr-linespacing::before {
  content: '\eb37';
}

.e-de-ctnr-lock::before {
  content: '\eb3a';
}

.e-de-ctnr-link::before {
  content: '\eb3c';
}

.e-de-ctnr-alignbottom::before {
  content: '\eb3d';
}

.e-de-selected-spellcheck-item::before {
  content: '\e935';
  font-family: 'e-icons';
  font-size: 10px;
}

.e-de-selected-underline-item::before {
  content: '\e935';
  font-family: 'e-icons';
  font-size: 10px;
}

.e-de-ctnr-table::before {
  content: '\eb3e';
}

.e-de-ctnr-tableofcontent::before {
  content: '\eb41';
}

.e-de-ctnr-pagenumber::before {
  content: '\eb43';
}

.e-de-ctnr-bold::before {
  content: '\eb47';
}

.e-de-ctnr-aligntop::before {
  content: '\eb49';
}

.e-de-ctnr-aligncenter::before {
  content: '\eb4a';
}

.e-de-ctnr-pagesetup::before {
  content: '\eb4c';
}

.e-de-ctnr-strokestyle::before {
  content: '\eb4d';
}

.e-de-ctnr-image::before {
  content: '\eb4f';
}

.e-de-ctnr-bookmark::before {
  content: '\eb51';
}

.e-de-ctnr-header::before {
  content: '\eb53';
}

.e-de-ctnr-bullets::before {
  content: '\eb5b';
}

.e-de-ctnr-showhide::before {
  content: '\eb5d';
  font-size: 16px;
}

.e-de-flip {
  transform: scaleX(-1);
}

.e-de-ctnr-print::before {
  content: '\eb5e';
}

.e-de-ctnr-alignright::before {
  content: '\eb5f';
}

.e-de-ctnr-footer::before {
  content: '\eb60';
}

.e-de-ctnr-outsideborder::before {
  content: '\eb66';
}

.e-de-ctnr-allborders::before {
  content: '\eb95';
}

.e-de-ctnr-insideborders::before {
  content: '\eb88';
}

.e-de-ctnr-mergecell::before {
  content: '\eb93';
}

.e-de-ctnr-aligntop::before {
  content: '\eb35';
}

.e-de-ctnr-alignbottom::before {
  content: '\eb0e';
}

.e-de-ctnr-aligncenter-table::before {
  content: '\eb0d';
}

.e-de-ctnr-bullet-none::before {
  content: '\e256';
}

.e-de-ctnr-bullet-dot::before {
  content: '\e270';
}

.e-de-ctnr-bullet-circle::before {
  content: '\e254';
}

.e-de-ctnr-bullet-square::before {
  content: '\e271';
}

.e-de-ctnr-bullet-flower::before {
  content: '\e267';
}

.e-de-ctnr-bullet-arrow::before {
  content: '\e253';
}

.e-de-ctnr-bullet-tick::before {
  content: '\e259';
}

.e-de-selected-item::before {
  content: '\e935';
}

.e-de-ctnr-break::before {
  content: '\eba6';
}

.e-de-ctnr-page-break::before {
  content: '\eba0';
}

.e-de-ctnr-section-break::before {
  content: '\eba2';
}

.e-de-ctnr-upload::before {
  content: '\e60f';
}

.e-de-ctnr-undo::before {
  content: '\e341';
}

.e-de-ctnr-find::before {
  content: '\e60e';
}

.e-de-ctnr-italic::before {
  content: '\e35a';
}

.e-de-ctnr-download::before {
  content: '\e603';
}

.e-de-ctnr-justify::before {
  content: '\e334';
}

.e-de-ctnr-highlight::before {
  content: '\ec15';
}

.e-de-ctnr-new::before {
  content: '\e823';
}

.e-de-ctnr-paste::before {
  content: '\e355';
}

.e-de-ctnr-subscript::before {
  content: '\e357';
}

.e-de-ctnr-fontcolor::before {
  content: '\ec22';
}

.e-de-ctnr-strikethrough::before {
  content: '\e332';
}

.e-de-ctnr-redo::before {
  content: '\e354';
}

.e-de-ctnr-increaseindent::before {
  content: '\e33f';
}

.e-de-ctnr-backgroundcolor::before {
  content: '\e35c';
}

.e-de-ctnr-open::before {
  content: '\e609';
}

.e-de-ctnr-underline::before {
  content: '\e343';
}

.e-de-ctnr-superscript::before {
  content: '\e352';
}

.e-de-ctnr-alignleft::before {
  content: '\e33a';
}

.e-de-ctnr-numbering::before {
  content: '\e346';
}

.e-de-ctnr-aligncenter::before {
  content: '\e35e';
}

.e-de-ctnr-decreaseindent::before {
  content: '\e35d';
}

.e-de-ctnr-clearall::before {
  content: '\e340';
}

.e-de-ctnr-leftborders::before {
  content: '\e291';
}

.e-de-ctnr-insideverticalborder::before {
  content: '\e287';
}

.e-de-ctnr-rightborder::before {
  content: '\e288';
}

.e-de-ctnr-topborder::before {
  content: '\e281';
}

.e-de-ctnr-insidehorizondalborder::before {
  content: '\e276';
}

.e-de-ctnr-bottomborder::before {
  content: '\e298';
}

.e-de-ctnr-strokesize::before {
  content: '\ec25';
}

.e-de-ctnr-highlightcolor::before {
  content: '\ec15';
}

.e-de-ctnr-insertleft::before {
  content: '\e285';
}

.e-de-ctnr-insertright::before {
  content: '\e284';
}

.e-de-ctnr-insertabove::before {
  content: '\e506';
}

.e-de-ctnr-insertbelow::before {
  content: '\e505';
}

.e-de-ctnr-deleterows::before {
  content: '\e283';
}

.e-de-ctnr-deletecolumns::before {
  content: '\e282';
}

.e-de-ctnr-cellbg-clr-picker::before {
  content: '\e35c';
  font-size: 15px;
}

.e-de-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text .e-de-text-wrap {
  height: 0;
  line-height: 0;
}

.e-de-ctnr-file-picker {
  left: -110em;
  position: fixed;
}

.e-de-ctnr-rtl {
  direction: rtl;
}

.e-de-ctnr-hglt-btn {
  display: inline-block;
  height: 25px;
  margin: 3px;
  width: 25px;
}

.e-color-selected,
.e-de-ctnr-hglt-btn:hover {
  border: 1px solid #ff8c00;
}

.e-hglt-no-color {
  height: 30px;
  padding-top: 1px;
  width: 157px;
}

.e-hglt-no-color:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}

.e-de-ctnr-hglt-no-color {
  font-size: 12px;
  font-weight: 400;
  left: 40px;
  padding-top: 11px;
  position: absolute;
  top: 100px;
}

.e-de-ctn-title {
  background-color: #3f51b5;
  color: #fff;
}

.e-de-tool-ctnr-properties-pane {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: calc(100% - 113px);
  width: 100%;
}

.e-de-ctnr-properties-pane {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: calc(100% - 113px);
  width: 100%;
}

.e-de-statusbar-seperator {
  border: 1px solid #ebebeb;
  height: 16px;
  margin-left: 15.5px;
  margin-top: 10px;
}

.e-de-statusbar-spellcheck {
  border-radius: 2px;
  height: 34px;
  margin-left: 7.5px;
  width: 91px;
}

.e-de-ctn {
  background-color: #fafafa;
  border: 1px solid #eee;
  height: calc(100%);
  position: relative;
  width: 100%;
}

.e-bigger .e-de-statusbar-spellcheck {
  border-radius: 2px;
  height: 34px;
  margin-left: 7.5px;
  width: 110px;
}

.e-de-ctnr-toolbar {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 77px;
  width: 100%;
}

.e-de-tlbr-wrapper .e-de-toolbar.e-toolbar,
.e-de-tlbr-wrapper .e-de-ctnr-properties-pane-btn {
  border: 0;
}

.e-de-pane {
  border-left: 1px solid #eee;
}

.e-de-pane-rtl {
  border-right: 1px solid #eee;
}

.e-de-pane > div button:not(.e-primary),
.e-de-pane-rtl > div button:not(.e-primary) {
  background-color: #eee;
}

.e-de-tool-ctnr-properties-pane {
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}

.e-de-ctnr-segment {
  margin-bottom: 12px;
}

.e-de-ctnr-segment > div:first-child,
.e-de-ctnr-segment > button:first-child {
  margin-right: 12px;
}

.e-de-ctnr-segment.e-de-ctnr-segment-rtl > div:first-child,
.e-de-ctnr-segment.e-de-ctnr-segment-rtl > button:first-child {
  margin-left: 12px;
  margin-right: 0;
}

.e-de-tlbr-wrapper {
  background-color: #fafafa;
  height: 77px;
  width: calc(100% - 75px);
}

.e-de-ctnr-prop-label {
  color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .05px;
  margin-bottom: 12px;
  opacity: .87;
}

.e-de-table-prop-label {
  margin-left: 12px;
}

.e-de-table-prop-label.e-de-rtl {
  margin-left: 0;
  margin-right: 12px;
}

.e-de-toolbar.e-toolbar {
  border-radius: 0;
}

.e-de-toolbar.e-toolbar .e-toolbar-items {
  height: 77px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
  height: 59px;
  margin: 0 5.5px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-start {
  margin-left: 12px;
  margin-right: 6px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-first {
  margin-right: 6px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-middle {
  margin-left: 6px;
  margin-right: 6px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-last {
  margin-left: 6px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-end {
  margin-left: 6px;
  margin-right: 12px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-image-focus :focus {
  background-color: rgba(184, 184, 184, 0.9584);
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  padding: 0;
  padding-bottom: 6px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus {
  padding: 0;
  padding-bottom: 6px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active {
  padding: 0;
  padding-bottom: 6px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover {
  padding: 0;
  padding-bottom: 6px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 0;
}

.e-de-overlay {
  filter: alpha(opacity=50);
  height: 100%;
  opacity: .5;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
  width: 100%;
}

.de-split-button > div:first-child {
  margin-right: 0;
}

.e-de-ctnr-properties-pane-btn {
  width: 75px;
}

.e-de-ctnr-properties-pane-btn .e-btn {
  background-color: #fafafa;
  border-radius: 0;
  box-shadow: none;
  color: #000;
  min-height: 100%;
  min-width: 100%;
}

.e-de-ctnr-properties-pane-btn .e-btn:focus {
  box-shadow: none;
}

.e-de-ctnr-properties-pane-btn .e-btn:active {
  box-shadow: none;
}

.e-de-ctnr-properties-pane-btn .e-btn:hover {
  box-shadow: none;
}

.e-de-showhide-btn {
  border: 0;
  height: 77px;
}

.e-de-showhide-btn-rtl {
  border: 0;
  height: 77px;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  padding: 0;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  display: table;
  font-size: 12px;
  margin: 0 6.5px;
  padding: 0;
  white-space: normal;
}

.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0;
}

.e-de-cntr-pane-padding {
  padding: 12px;
}

.e-de-prop-pane {
  width: 262px;
}

.e-bigger .e-de-cntr-pane-padding {
  padding: 16px;
}

.e-bigger .e-de-prop-pane {
  width: 270px;
}

.e-de-ctnr-group-btn.e-btn-group:not(.e-outline) {
  box-shadow: none;
  height: 28px;
}

.e-de-ctnr-group-btn.e-btn-group:not(.e-outline):focus {
  box-shadow: none;
}

.e-de-ctnr-group-btn.e-btn-group:not(.e-outline):active {
  box-shadow: none;
}

.e-de-ctnr-group-btn.e-btn-group:not(.e-outline):hover {
  box-shadow: none;
}

.e-de-ctnr-group-btn button {
  box-shadow: none;
  height: 28px;
}

.e-de-ctnr-group-btn button:focus {
  box-shadow: none;
}

.e-de-ctnr-group-btn button:active {
  box-shadow: none;
}

.e-de-ctnr-group-btn button:hover {
  box-shadow: none;
}

.e-de-property-div-padding {
  border-bottom: 0.5px solid #e0e0e0;
  padding-bottom: 11.5px;
  padding-top: 12.5px;
}

.e-de-ctnr-dropdown-ftr {
  border-top: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  font-size: 12px;
  line-height: 40px;
  text-indent: 1.2em;
}

.e-de-char-fmt-btn-left > button {
  width: 38.5px;
}

.e-de-char-fmt-btn-right > button {
  width: 36px;
}

.e-de-panel-left-width {
  width: 150px;
}

.e-bigger .e-de-panel-left-width {
  width: 150px;
}

.e-bigger .e-de-char-fmt-btn-left > button {
  width: 38.5px;
}

.e-de-panel-right-width {
  width: 72px;
}

.e-de-cntr-highlight-pane {
  border: 1px solid #eee;
}

.e-de-btn-hghlclr > button:first-child {
  padding: 1px !important;
}

.e-de-ctnr-hglt-color {
  font-size: 12px;
  font-weight: 400;
  height: 18px !important;
  width: 18px !important;
}

.e-de-font-clr-picker > div button,
.e-de-font-clr-picker > button {
  width: 30.8px;
}

.e-de-floating-menu.e-de-bullets-menu .e-de-floating-menuitem-md {
  height: 55px;
  padding: 4px !important;
  width: 60px;
}

.e-de-floating-menu.e-de-bullets-menu .e-de-floating-menuitem-md:hover {
  border: 3px solid rgba(0, 0, 0, 0.12);
  padding: 2px !important;
}

.e-de-floating-menu.e-de-bullets-menu .e-de-floating-bullet-menuitem-md {
  height: 38px;
  padding: 4px !important;
  width: 38px;
}

.e-de-floating-menu.e-de-bullets-menu .e-de-floating-bullet-menuitem-md:hover {
  border: 3px solid rgba(0, 0, 0, 0.12);
  padding: 2px !important;
}

.e-de-list-header-presetmenu {
  cursor: pointer;
  font-size: 11px;
  line-height: 14px;
  overflow: hidden;
  text-align: left;
  min-width: 50px;
  white-space: nowrap;
  width: 100%;
}

.e-de-bullet-list-header-presetmenu {
  cursor: pointer;
  font-size: 14px;
  left: -11px;
  line-height: 26px;
  min-width: 50px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.e-rtl .e-de-bullet-list-header-presetmenu {
  cursor: pointer;
  font-size: 14px;
  left: 10px;
  line-height: 26px;
  min-width: 50px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.e-de-bullet {
  font-size: 42px;
}

.e-de-list-header-presetmenu .e-de-list-line {
  border-bottom: 1px solid #ccc;
  margin-left: 5px;
  width: 100%;
}

.e-de-toc-optionsdiv {
  margin-bottom: 11.5px;
  margin-left: 5.5px;
  margin-top: 15.5px;
}

.e-de-toc-optionsdiv.e-de-rtl {
  margin-right: 5.5px;
  margin-left: 0;
}

.e-de-list-header-presetmenu div span {
  color: #aaa;
  display: inline-block;
  vertical-align: middle;
}

.e-de-floating-menu .e-de-floating-menuitem,
.e-de-floating-menu .e-de-menuitem-none {
  cursor: pointer;
  height: 70px;
  padding: 0 !important;
  margin: 0 5px 5px 0 !important;
  width: 70px;
}

.e-de-list-thumbnail .e-de-list-items {
  float: left;
}

.e-de-list-thumbnail .e-de-list-items {
  border: 1px solid #e4e4e4;
  clear: initial;
  display: inline-block;
  height: auto;
  margin: 5px;
  padding: 2px;
  text-align: center;
  width: auto;
}

.e-de-list-items {
  cursor: pointer;
  background: #fafafa;
  box-sizing: border-box;
  list-style: none;
  padding: 7px 10px 7px 10px;
  position: relative;
}

.e-de-list-item-size {
  font-size: 14px;
}

.e-de-floating-menuitem.e-de-floating-menuitem-md.e-de-list-items.e-de-list-item-size.de-list-item-selected,
.e-de-floating-menuitem.e-de-floating-bullet-menuitem-md.e-de-list-items.e-de-list-item-size.de-list-item-selected {
  border: 3px solid #3f51b5;
  padding: 2px !important;
}

.e-de-floating-menu {
  padding: 10px 4px 5px 10px !important;
}

.e-de-list-container {
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: inline-block;
  line-height: normal;
  margin: 0;
  outline: 0;
  padding: 5px 0;
  position: absolute;
  width: auto;
  z-index: 10020;
}

.e-de-ctnr-list {
  font-size: 29px;
  vertical-align: top;
}

.e-de-image-property {
  padding-left: 32px;
}

.e-de-img-prty-span {
  color: #000;
  left: 8px;
  position: absolute;
  top: 6px;
}

.e-btn-toggle {
  background-color: rgba(184, 184, 184, 0.9584) !important;
  outline: #fafafa 0 solid;
  outline-offset: 0;
  box-shadow: none !important;
}

.e-btn-toggle:hover {
  background-color: rgba(184, 184, 184, 0.9584) !important;
  outline: #fafafa 0 solid;
  outline-offset: 0;
  box-shadow: none !important;
}

.e-de-toc-template1 {
  background: #fafafa;
  border: 1px solid #eee;
  color: rgba(0, 0, 0, 0.54);
  height: 129px;
  margin-left: 78px;
  width: 94px;
}

.e-de-toc-template1.e-de-rtl {
  margin-left: 0;
  margin-right: 78px;
}

.e-de-toc-template1-content1 {
  font-size: 10px;
  height: 11px;
  margin-left: 5.4px;
  margin-top: 6.7px;
  width: 78px;
}

.e-de-toc-template1-content2 {
  font-size: 8px;
  height: 9px;
  margin-left: 20.4px;
  margin-top: 5.7px;
  width: 63px;
}

.e-de-toc-template1-content3 {
  font-size: 7px;
  height: 8px;
  margin-left: 28.4px;
  margin-top: 6.7px;
  width: 55px;
}

.e-de-prop-sub-label {
  color: #000;
  font-size: 13px;
  margin-bottom: 8px;
}

.e-de-toc-checkbox1 {
  height: 14px;
  margin-top: 14px;
}

.e-de-toc-checkbox2 {
  height: 14px;
  margin-top: 14px;
}

.e-de-toc-checkbox3 {
  height: 14px;
  margin-top: 14px;
}

.e-de-status-bar {
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  width: 100%;
}

.e-de-ctnr-pg-no {
  color: #000;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 13px;
  height: 100%;
  width: 100px;
}

.e-de-ctnr-pg-no-spellout {
  color: #000;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 13px;
  height: 100%;
  width: calc(100% - 75px);
}

.e-bigger .e-de-ctnr-pg-no-spellout {
  color: #000;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 13px;
  height: 100%;
  width: calc(100% - 90px);
}

.e-de-statusbar-zoom-spell {
  background-color: #fafafa;
  border: 0;
  color: #000;
  float: right;
  height: 34px;
  margin-left: calc(100% - 292px);
}

.e-bigger .e-de-statusbar-zoom-spell {
  background-color: #fafafa;
  border: 0;
  color: #000;
  float: right;
  height: 34px;
  margin-left: calc(100% - 325px);
}

.e-de-btn-cancel {
  margin-left: 10px;
}

.e-de-btn-cancel-rtl {
  margin-left: 0;
  margin-right: 10px;
}

.e-de-prop-header-label {
  color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: .05px;
  opacity: .87;
}

.e-de-prop-separator-line {
  border-bottom: 1px solid #eee;
}

.e-de-status-bar > div label {
  font-weight: normal;
}

.e-de-stylediv {
  padding-left: 12px;
}

.e-de-stylediv-rtl {
  padding-left: 0;
  padding-right: 12px;
}

.e-de-border-style-div {
  margin-left: 12px;
}

.e-de-border-style-div.e-de-rtl {
  margin-left: 0;
  margin-right: 12px;
}

.e-de-insert-del-cell {
  margin-left: 12px;
}

.e-de-insert-del-cell.e-de-rtl {
  margin-left: 0;
  margin-right: 12px;
}

.e-de-cell-margin {
  margin-left: 12px;
}

.e-de-align-text {
  margin-left: 12px;
}

.e-de-align-text.e-de-rtl {
  margin-left: 0;
  margin-right: 12px;
}

.e-de-border-size-button {
  height: 28px;
  margin-top: 14px;
  width: 100px;
}

.e-de-color-picker {
  height: 28px;
  width: 100px;
}

.e-de-cell-div {
  margin-left: 12px;
}

.e-de-cell-div.e-de-rtl {
  margin-left: 0;
  margin-right: 12px;
}

.e-de-cell-text-box {
  margin-right: 12px;
}

.e-de-prop-fill-label {
  margin-left: 10.3px;
  margin-right: 8px;
}

.e-de-prop-fill-label.e-de-rtl {
  margin-left: 8px;
  margin-right: 10.3px;
}

.e-de-grp-btn-ctnr .e-de-ctnr-group-btn,
.e-bigger .e-de-grp-btn-ctnr .e-de-ctnr-group-btn {
  height: 42px !important;
}

.e-de-grp-btn-ctnr .e-de-ctnr-group-btn > button,
.e-bigger .e-de-grp-btn-ctnr .e-de-ctnr-group-btn > button {
  height: 42px;
  width: 42px;
}

.e-de-border-clr-picker .e-split-btn-wrapper > button:first-child {
  width: 70px;
}

.e-bigger .de-split-button > div:first-child {
  margin-right: 0;
}

.e-bigger .e-de-border-clr-picker .e-split-btn-wrapper > button:first-child {
  width: 66px;
}

.e-bigger .e-de-prop-fill-label {
  margin-left: 14.5px;
  margin-right: 9.8px;
}

.e-bigger .e-de-prop-fill-label.e-de-rtl {
  margin-left: 9.8px;
  margin-right: 14.5px;
}

.e-bigger .e-de-cell-text-box {
  margin-right: 16px;
}

.e-bigger .e-de-cell-div {
  margin-left: 16px;
}

.e-bigger .e-de-cell-div.e-de-rtl {
  margin-left: 0;
  margin-right: 16px;
}

.e-bigger .e-de-border-style-div {
  margin-left: 16px;
}

.e-bigger .e-de-border-style-div.e-de-rtl {
  margin-left: 0;
  margin-right: 16px;
}

.e-bigger .e-de-color-picker {
  height: 36px;
  width: 96px;
}

.e-bigger .e-de-border-size-button {
  height: 36px;
  margin-top: 14px;
  width: 96px;
}

.e-bigger .e-de-align-text {
  margin-left: 16px;
}

.e-bigger .e-de-align-text.e-de-rtl {
  margin-left: 0;
  margin-right: 16px;
}

.e-bigger .e-de-insert-del-cell {
  margin-left: 16px;
}

.e-bigger .e-de-insert-del-cell.e-de-rtl {
  margin-left: 0;
  margin-right: 16px;
}

.e-bigger .e-de-cell-margin {
  margin-left: 14px;
}

.e-bigger .e-de-cell-margin.e-de-rtl {
  margin-left: 0;
  margin-right: 14px;
}

.e-bigger .e-de-stylediv {
  padding-left: 16px;
}

.e-bigger .e-de-stylediv-rtl {
  padding-right: 16px;
}

.e-bigger .e-de-tool-ctnr-properties-pane {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: calc(100% - 118px);
  width: 100%;
}

.e-bigger .e-de-ctnr-properties-pane {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: calc(100% - 118px);
  width: 100%;
}

.e-bigger .e-de-ctn {
  background-color: #fafafa;
  border: 1px solid #eee;
  height: calc(100%);
  position: relative;
  width: 100%;
}

.e-bigger .e-de-ctnr-toolbar {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 82px;
  width: 100%;
}

.e-bigger .e-de-tlbr-wrapper .e-de-toolbar.e-toolbar,
.e-bigger .e-de-tlbr-wrapper .e-de-ctnr-properties-pane-btn {
  border: 0;
}

.e-bigger .e-de-pane {
  border-left: 1px solid #eee;
}

.e-bigger .e-de-pane-rtl {
  border-right: 1px solid #eee;
}

.e-bigger .e-de-pane > div button:not(.e-primary),
.e-bigger .e-de-pane-rtl > div button:not(.e-primary) {
  background-color: #eee;
}

.e-bigger .e-de-ctnr-segment {
  margin-bottom: 16px;
}

.e-bigger .e-de-ctnr-segment > div:first-child,
.e-bigger .e-de-ctnr-segment > button:first-child {
  margin-right: 12px;
}

.e-bigger .e-de-ctnr-segment.e-de-ctnr-segment-rtl > div:first-child,
.e-bigger .e-de-ctnr-segment.e-de-ctnr-segment-rtl > button:first-child {
  margin-left: 12px;
  margin-right: 0;
}

.e-bigger .e-de-tlbr-wrapper {
  background-color: #fafafa;
  height: 82px;
  width: calc(100% - 75px);
}

.e-bigger .e-de-ctnr-prop-label {
  color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .05px;
  margin-bottom: 16px;
  opacity: .87;
}

.e-bigger .e-de-table-prop-label {
  margin-left: 14.5px;
}

.e-bigger .e-de-table-prop-label.e-de-rtl {
  margin-left: 0;
  margin-right: 14.5px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items {
  height: 82px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
  height: 25px;
  margin: 0 7.5px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-start {
  margin-left: 16px;
  margin-right: 8px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-first {
  margin-right: 8px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-middle {
  margin-left: 8px;
  margin-right: 8px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-last {
  margin-left: 8px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-end {
  margin-left: 8px;
  margin-right: 16px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-image-focus :focus {
  background-color: rgba(184, 184, 184, 0.9584);
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  padding: 0;
  padding-bottom: 6px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus {
  padding: 0;
  padding-bottom: 6px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active {
  padding: 0;
  padding-bottom: 6px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover {
  padding: 0;
  padding-bottom: 6px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 0;
}

.e-bigger .e-de-overlay {
  filter: alpha(opacity=50);
  height: 100%;
  opacity: .5;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
  width: 100%;
}

.e-bigger .e-de-ctnr-properties-pane-btn {
  width: 75px;
}

.e-bigger .e-de-ctnr-properties-pane-btn .e-btn {
  background-color: #fafafa;
  border-radius: 0;
  box-shadow: none;
  min-height: 100%;
  min-width: 100%;
}

.e-bigger .e-de-ctnr-properties-pane-btn .e-btn:focus {
  box-shadow: none;
}

.e-bigger .e-de-ctnr-properties-pane-btn .e-btn:active {
  box-shadow: none;
}

.e-bigger .e-de-ctnr-properties-pane-btn .e-btn:hover {
  box-shadow: none;
}

.e-bigger .e-de-showhide-btn {
  border: 0;
  height: 82px;
}

.e-bigger .e-de-showhide-btn-rtl {
  border: 0;
  height: 82px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  padding: 0;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  display: table;
  font-size: 13px;
  margin: 0 6px;
  padding: 0;
  white-space: normal;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  font-size: 16px;
  padding: 0;
}

.e-bigger .e-de-ctnr-group-btn.e-btn-group:not(.e-outline) {
  box-shadow: none;
  height: 36px;
}

.e-bigger .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):focus {
  box-shadow: none;
}

.e-bigger .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):active {
  box-shadow: none;
}

.e-bigger .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):hover {
  box-shadow: none;
}

.e-bigger .e-de-ctnr-group-btn button {
  box-shadow: none;
  height: 36px;
}

.e-bigger .e-de-ctnr-group-btn button:focus {
  box-shadow: none;
}

.e-bigger .e-de-ctnr-group-btn button:active {
  box-shadow: none;
}

.e-bigger .e-de-ctnr-group-btn button:hover {
  box-shadow: none;
}

.e-bigger .e-de-property-div-padding {
  border-bottom: 0.5px solid #e0e0e0;
  padding-bottom: 14.5px;
  padding-top: 15.5px;
}

.e-bigger .e-de-ctnr-dropdown-ftr {
  border-top: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  font-size: 12px;
  line-height: 40px;
  text-indent: 1.2em;
}

.e-bigger .e-de-char-fmt-btn > button {
  width: 38.5px;
}

.e-bigger .e-de-btn-hghlclr > button:first-child {
  padding: 0 6px !important;
}

.e-bigger .e-de-ctnr-hglt-color {
  font-size: 12px;
  font-weight: 400;
  height: 25px !important;
  width: 25px !important;
  border-radius: 4px;
}

.e-bigger .e-de-font-clr-picker > div button,
.e-bigger .e-de-font-clr-picker > button {
  width: auto;
}

.e-bigger .e-de-ctnr-list {
  font-size: 29px;
  vertical-align: top;
}

.e-bigger .e-de-image-property {
  padding-left: 32px;
}

.e-bigger .e-de-img-prty-span {
  color: #000;
  left: 10px;
  position: absolute;
  top: 12px;
}

.e-bigger .e-btn-toggle {
  background-color: rgba(184, 184, 184, 0.9584) !important;
  box-shadow: none !important;
  outline: #fafafa 0 solid;
  outline-offset: 0;
}

.e-bigger .e-btn-toggle:hover {
  background-color: rgba(184, 184, 184, 0.9584) !important;
  outline: #fafafa 0 solid;
  outline-offset: 0;
  box-shadow: none !important;
}

.e-bigger .e-de-toc-template1 {
  background: #fafafa;
  border: 1px solid #eee;
  color: rgba(0, 0, 0, 0.54);
  height: 129px;
  margin-left: 78px;
  width: 94px;
}

.e-bigger .e-de-toc-template1-content1 {
  font-size: 10px;
  height: 11px;
  margin-left: 5.4px;
  margin-top: 6.7px;
  width: 78px;
}

.e-bigger .e-de-toc-template1-content2 {
  font-size: 8px;
  height: 9px;
  margin-left: 20.4px;
  margin-top: 5.7px;
  width: 63px;
}

.e-bigger .e-de-toc-template1-content3 {
  font-size: 7px;
  height: 8px;
  margin-left: 28.4px;
  margin-top: 6.7px;
  width: 55px;
}

.e-bigger .e-de-toc-optionsdiv {
  margin-bottom: 11.5px;
  margin-left: 5.5px;
  margin-top: 15.5px;
}

.e-bigger .e-de-toc-optionsdiv.e-de-rtl {
  margin-right: 5.5px;
  margin-left: 0;
}

.e-bigger .e-de-prop-sub-label {
  font-size: 13px;
  margin-bottom: 8.5px;
}

.e-bigger .e-de-btn-cancel {
  margin-left: 10px;
}

.e-bigger .e-de-status-bar {
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 36px;
  width: 100%;
}

.e-bigger .e-de-statusbar-zoom {
  background-color: #fafafa;
  border: 0;
  float: right;
  height: 34px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  line-height: 25px;
}

.e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text .e-de-text-wrap {
  height: 6px;
  line-height: 0;
}
